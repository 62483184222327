const FooterComponent = () => {
    return (
        <footer id="footer">
            <div className="container">
                <div className="credits">
                  Created by me using React and NodeJS.
                </div>
                <div className="credits">
                  Template design from <a href="https://bootstrapmade.com/">BootstrapMade</a>.
                </div>
            </div>
        </footer>
    );
}

export default FooterComponent;
